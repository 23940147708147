import React from "react";

const Login = React.lazy(() => import("../Pages/Login"));
const DashBoard = React.lazy(() => import("../Pages/DashBoard"));
const ChildRegistration = React.lazy(() =>
  import("../Pages/ChildRegistration")
);
const ChangePassword = React.lazy(() => import("../Pages/ChangePassword"));
const Pin = React.lazy(() => import("../Pages/Pin"));
const UpdateProfile = React.lazy(() => import("../Pages/UpdateProfile"));
const DrawDetails = React.lazy(() => import("../Pages/DrawDetails"));
const PinPassword = React.lazy(() => import("../Pages/PinPassword"));
const ContactUs = React.lazy(() => import("../Pages/ContactUs"));
const Contact = React.lazy(() => import("../Pages/Contact"));

export const PublicroutesArray = [
  { path: "/", component: Login },
  { path: "/contact", component: Contact },
];

export const PrivateroutesArray = [
  { path: "/dashboard", component: DashBoard },
  { path: "/child-registration", component: ChildRegistration },
  { path: "/change-password", component: ChangePassword },
  { path: "/change-pin", component: Pin },
  { path: "/update-profile", component: UpdateProfile },
  { path: "/drew-details", component: DrawDetails },
  { path: "#", component: DashBoard },
  { path: "#", component: DashBoard },
  { path: "/pin-password", component: PinPassword },
  { path: "/contact-us", component: ContactUs },
];
